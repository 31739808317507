/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/aria-unsupported-elements */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-array-index-key */

import {
  Box,
  Paper,
  Title,
  Grid,
  Text,
  Group,
  SelectItemProps,
  Select,
  createStyles,
  Button,
  useMantineTheme,
  Popover,
  Skeleton,
} from '@mantine/core'

import dayjs from 'dayjs'
import { DateRangePicker } from '@mantine/dates'
import axios, { AxiosError, AxiosResponse } from 'axios'
import _, { get, map, sortBy } from 'lodash'
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { IconFilter, IconTableExport } from '@tabler/icons'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../hooks'
import { API_SEVICES } from '../../config/services'
import {
  API_ENDPOINTS,
  AlertColor,
  AlertIcons,
  PAGESIZES,
  ROLEPROVIDED,
  BRANDID,
  STOREID,
  STORECODE,
} from '../../constants'
import { openSnackbar } from '../../redux/snackbar'
import { useAppDispatch } from '../../redux/store'
import { getLocalStorageItem } from '../../utils/localStorage'
import CustomTable, { CustomColum } from '../../components/NewcustomTable'
import VerticalBarChart from '../../components/VerticalBarChart'

interface ItemProps extends SelectItemProps {
  _id: any
  name: string
  description: string
  cityName: string
}

const useStyles = createStyles((theme) => ({
  outside: {
    opacity: 0,
  },

  weekend: {
    color: `${theme.colors.blue[6]} !important`,
  },

  selected: {
    color: `${theme.white} !important`,
  },
  GraphWrapper: {
    cursor: 'pointer',
    width: '100%',
    borderRadius: 10,
  },
}))

/* eslint @typescript-eslint/no-var-requires: "off" */
const FileDownload = require('js-file-download')

export default function Calllog() {
  const filterRef = useRef<HTMLInputElement>(null)
  const theme = useMantineTheme()

  const [loading, setLoading] = useState(false)
  const [recordData, setRecordData] = useState([])
  const [state, setState] = useState([])
  const [page, setPage] = useState(1)
  const [pageNo, setPageNo] = useState(1)
  const [pageSize, setPageSize] = useState(PAGESIZES[0])
  const [storeVal, setStoreVal] = useState<any>(null)
  const [brandVal, setBrandVal] = useState<any>(null)
  const [countFrom, setCountForm] = useState<any>()
  const [countTo, setCountTo] = useState<any>()
  const [brandData, setBrandData] = useState([])
  const [totalRowSize, setTotalRowSize]: any = useState(0)
  const [graphLoading, setGraphLoading] = useState(false)
  const [exportLoading, setExportLoading] = useState(false)
  const [rangedate, setRangeDate] = useState<[Date | null, Date | null]>([
    new Date(),
    new Date(),
  ])
  const navigate = useNavigate()
  const [week, setWeek] = useState<any>([
    moment(new Date()).subtract(6, 'day').format('YYYY-MM-DD'),
    moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
  ])
  const [weeklyRange, setWeeklyRange] = useState<any>([
    moment(new Date()).subtract(6, 'day').format('YYYY-MM-DD'),
    moment(new Date()).format('YYYY-MM-DD'),
  ])
  // const role = useAppSelector((state) => state.role)
  // const brandId = role.brandId || BRANDID || null
  const getDaysArray = function (start: any, end: any) {
    let arr = []
    let dt
    for (
      arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt))
    }
    return arr
  }
  const daylist = getDaysArray(
    new Date(weeklyRange[0]),
    new Date(weeklyRange[1])
  )
  daylist.map((v: any) => v.toISOString().slice(0, 10)).join('')

  const weeklyDays = daylist.map((val: any) => moment(val).format('DD-MM-YYYY'))

  const [monthRange, setMonthRange] = useState<any>([
    moment()
      .subtract(3, 'month')
      .endOf('month')
      .add(1, 'day')
      .format('YYYY-MM-DD'),
    moment().add(1, 'day').format('YYYY-MM-DD'),
  ])

  const fromDate = moment(monthRange[0], 'YYYY-MM-DD')
  const toDate = moment(monthRange[1], 'YYYY-MM-DD')
  const monthData: string[] = []

  while (toDate > fromDate || fromDate.format('M') === toDate.format('M')) {
    monthData.push(fromDate.format('YYYY-MM'))
    fromDate.add(1, 'month')
  }

  const fromData = moment(monthRange[0], 'YYYY-MM-DD')
  const toData = moment(monthRange[1], 'YYYY-MM-DD')
  const monthDate: string[] = []
  while (toData > fromData || fromData.format('M') === toData.format('M')) {
    monthDate.push(fromData.format('MMMM-YYYY'))
    fromData.add(1, 'month')
  }

  const [halfYearRange, setHalfYearRange] = useState<any>([
    moment()
      .subtract(6, 'month')
      .endOf('month')
      .add(1, 'day')
      .format('YYYY-MM-DD'),
    moment().add(1, 'day').format('YYYY-MM-DD'),
  ])
  const fromYear = moment(halfYearRange[0], 'YYYY-MM-DD')
  const toYear = moment(halfYearRange[1], 'YYYY-MM-DD')
  const YearData: any[] = []

  while (toYear > fromYear || fromYear.format('M') === toYear.format('M')) {
    YearData.push(fromYear.format('YYYY-MM'))
    fromYear.add(1, 'month')
  }

  const fromYearData = moment(halfYearRange[0], 'YYYY-MM-DD')
  const toYearData = moment(halfYearRange[1], 'YYYY-MM-DD')
  const YearDatas: any[] = []

  while (
    toYearData > fromYearData ||
    fromYearData.format('M') === toYearData.format('M')
  ) {
    YearDatas.push(fromYearData.format('MMMM-YYYY'))
    fromYearData.add(1, 'month')
  }

  const [weeklyData, setWeeklyData] = useState<any>(null)
  const [quaterlyData, setQuaterlyData] = useState<any>([])
  const [halfYearlyData, setHalfYearlyData] = useState<any>([])
  const { classes, cx } = useStyles()
  const dispatch = useAppDispatch()
  const role = useAppSelector((stat) => stat.role)
  const brandID = role.brandId || BRANDID || null
  const storeID = role.storeId || STOREID || null
  const storeCode = role.storeCode || STORECODE || null
  const roleProvided = role.role || ROLEPROVIDED
  console.log('storeCode', storeCode)

  // eslint-disable-next-line react/display-name
  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, name, description, cityName, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
            {description || cityName ? (
              <Text size="xs" opacity={0.65}>
                {description}-{cityName}
              </Text>
            ) : null}
          </div>
        </Group>
      </div>
    )
  )

  useEffect(() => {
    const match = {
      _id: brandID,
    }
    let mat
    if (brandID === null && roleProvided === 'admin') {
      mat = JSON.stringify({ _id: null })
    } else if (brandID === null && roleProvided === 'admin manager') {
      mat = JSON.stringify(match)
    } else if (roleProvided === 'user') {
      mat = JSON.stringify(match)
    } else if (roleProvided === 'brand manager') {
      mat = JSON.stringify(match)
    } else if (brandID !== null && roleProvided === 'CRM manager') {
      mat = JSON.stringify(match)
    } else if (brandID === null && roleProvided === 'CRM manager') {
      mat = JSON.stringify({ _id: null })
    } else {
      mat = JSON.stringify({})
    }
    const controller = new AbortController()
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BRAND}?match=${mat}`,
      (res: AxiosResponse) => {
        setBrandData(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Brand Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )
    let storeMatch
    if (brandID === null && roleProvided === 'admin') {
      storeMatch = JSON.stringify({
        brandId: '',
      })
    } else if (brandID === null && roleProvided === 'CRM manager') {
      storeMatch = JSON.stringify({
        brandId: '',
      })
    } else if (brandVal !== null) {
      storeMatch = JSON.stringify({ brandId: brandVal, status: true })
    } else if (brandID && roleProvided === 'admin manager') {
      storeMatch = JSON.stringify({
        brandId: brandID?.[0],
        storeId: storeCode,
        status: true,
      })
    } else if (brandID) {
      storeMatch = JSON.stringify({ brandId: brandID?.[0], status: true })
    } else {
      storeMatch = JSON.stringify({ status: true })
    }

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_STORE}?match=${storeMatch}`,
      (res: AxiosResponse) => {
        console.log('res store', res)
        setState(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Store Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )
    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandID, brandVal, roleProvided, storeID])

  const brandDetails = brandData.map((item: any) => ({
    ...item,
    value: item._id,
    label: item.name,
  }))

  // Getting exotel Call log Table details brand as array[brandID]

  useEffect(() => {
    setLoading(true)

    let match
    if (storeVal === null && brandVal === null) {
      if (
        roleProvided === 'admin manager' &&
        brandID !== null &&
        storeID.length > 0
      ) {
        match = {
          brand: brandID,
          from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
          to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
          store: storeID,
        }
      } else if (roleProvided === 'CRM manager' && brandID !== null) {
        match = {
          brand: [brandID?.[0]], // notify
          from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
          to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
        }
      } else if (roleProvided === 'brand manager' && brandID !== null) {
        match = {
          brand: brandID,
          from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
          to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
        }
      } else if (roleProvided === 'user') {
        match = {
          store: storeID,
          brand: brandID,
          from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
          to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
        }
      } else if (roleProvided === 'super admin') {
        match = {
          from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
          to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
        }
      } else if (roleProvided === 'admin') {
        match = {
          brand: brandID,
          from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
          to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
        }
      }
    } else if (roleProvided === 'admin' && brandID) {
      match = {
        brand: brandID,
        from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
        to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
        store: storeVal !== null ? [storeVal] : null,
      }
    } else if (roleProvided === 'CRM manager' && brandID) {
      match = {
        brand: [brandVal],
        from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
        to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
        store: storeVal !== null ? [storeVal] : null,
      }
    } else if (roleProvided === 'brand manager' && brandID) {
      match = {
        brand: brandID,
        store: storeVal !== null ? [storeVal] : null,
        from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
        to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
      }
    } else if (roleProvided === 'admin manager' && brandID) {
      match = {
        brand: brandID,
        from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
        to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
        store: storeVal !== null ? [storeVal] : null,
      }
    } else {
      match = {
        brand: [brandVal],
        store: storeVal !== null ? [storeVal] : null,
        from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
        to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
      }
    }

    const clean = (obj: any) => {
      for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName]
        }
      }

      return obj
    }

    setLoading(true)

    const mat = clean(match)

    const match1 = JSON.stringify(mat)

    const controller = new AbortController()

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_EXOTEL_CALLLOG}?pageNo=${page}&size=${pageSize}&match=${match1}`,
      (res: AxiosResponse) => {
        if (res.data.data.length > 0) {
          console.log('datewise calllogs', res)
          setLoading(false)
          setTotalRowSize(res.data.TotalRecords)
          setRecordData(res.data.data)
        } else {
          setLoading(true)
        }
      },
      (err: AxiosError) => {
        setLoading(false)
        setRecordData([])
        setTotalRowSize(0)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        console.error(err)
      },
      controller.signal
    )
    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, rangedate, brandVal, storeVal, brandData])

  const Token = getLocalStorageItem('token')
  const onDownload = () => {
    setExportLoading(true)
    let match

    if (roleProvided === 'super admin') {
      if (brandVal === null && storeVal === null) {
        match = JSON.stringify({
          from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
          to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
        })
      } else if (brandVal !== null && storeVal === null) {
        match = JSON.stringify({
          from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
          to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
          brand: brandVal,
        })
      } else if (storeVal !== null) {
        match = JSON.stringify({
          from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
          to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
          store: storeVal !== null ? [storeVal] : null,
          brand: brandVal,
        })
      }
    } else if (roleProvided === 'admin') {
      if (brandVal === null && storeVal === null) {
        match = JSON.stringify({
          from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
          to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
          brand: brandID?.[0],
        })
      } else if (storeVal !== null) {
        match = JSON.stringify({
          from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
          to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
          store: storeVal !== null ? [storeVal] : null,
          brand: brandID?.[0],
        })
      }
    } else if (roleProvided === 'CRM manager') {
      if (brandVal === null && storeVal === null) {
        match = JSON.stringify({
          from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
          to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
          brand: brandDetails?.[0]?.value, // notify
        })
      } else if (storeVal !== null) {
        match = JSON.stringify({
          from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
          to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
          store: storeVal !== null ? [storeVal] : null,
          brand: brandVal,
        })
      } else if (brandVal !== null && storeVal === null) {
        match = JSON.stringify({
          from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
          to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
          brand: brandVal,
        })
      }
    } else if (roleProvided === 'brand manager') {
      if (brandVal === null && storeVal === null) {
        match = JSON.stringify({
          from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
          to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
          brand: brandID?.[0],
        })
      } else if (storeVal !== null) {
        match = JSON.stringify({
          from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
          to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
          store: storeVal !== null ? [storeVal] : null,
          brand: brandID?.[0],
        })
      }
    } else if (roleProvided === 'user') {
      match = JSON.stringify({
        from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
        to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
        store: storeID,
        brand: brandID?.[0],
      })
    } else if (roleProvided === 'admin manager') {
      if (storeVal === null) {
        match = JSON.stringify({
          from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
          to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
          store: storeID,
          brand: brandID?.[0],
        })
      } else {
        match = JSON.stringify({
          from: dayjs(rangedate[0]).format('YYYY-MM-DD'),
          to: dayjs(rangedate[1]).add(1, 'days').format('YYYY-MM-DD'),
          store: storeVal !== null ? [storeVal] : null,
        })
      }
    }

    const path = `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.EXPORT_EXOTEL_CALLLOG}?match=${match}`
    axios({
      url: path,
      headers: {
        Authorization: `Bearer ${Token}`,
      },

      method: 'post',
      responseType: 'blob', // important
      data: {
        match: `${match}`,
        from: `${dayjs(rangedate[0]).format('YYYY-MM-DD')}`,
        to: `${dayjs(rangedate[1]).add(1, 'day').format('YYYY-MM-DD')}`,
      },
    })
      .then((res) => {
        console.log('resres', res)
        setExportLoading(false)
        FileDownload(
          res.data,
          `Close_Bi(${dayjs(rangedate[0]).format('DD-MM-YYYY')}_${dayjs(
            rangedate[1]
          ).format('DD-MM-YYYY')}).xlsx`
        )
      })
      .catch((error) => {
        console.log('clicked')
        setExportLoading(false)
        dispatch(
          openSnackbar({
            messege: get(
              error,
              'response.data.message',
              'Failed To Download File'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        if (
          get(error, 'response.status', '') === 401 ||
          get(error, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        console.error('download file error', error)
      })
  }

  useEffect(() => {
    let rowDataSize

    if (totalRowSize >= pageSize) {
      rowDataSize = totalRowSize / pageSize

      if (rowDataSize - Math.floor(rowDataSize) !== 0) {
        setPageNo(Math.ceil(rowDataSize))
      } else {
        setPageNo(rowDataSize)
      }
    } else {
      rowDataSize = Math.ceil(totalRowSize / pageSize)
      setPageNo(rowDataSize)
    }
  }, [pageSize, totalRowSize, recordData])

  const colDef: CustomColum[] = useMemo(
    () => [
      {
        accessor: 'store.storeId',
        Header: 'Store Id',
        textAlignment: 'bottam',
        minWidth: 80,
        width: 100,
      },
      {
        accessor: 'storeName',
        Header: 'Store Name',
        textAlignment: 'center',
        minWidth: 200,
        Cell: ({ ...value }: any) => (
          <div>
            {value.row.original.store.storeName}
            <div style={{ color: 'grey', fontSize: '13px' }}>
              {get(value.row.original.store, 'locality', '')},
              {get(value.row.original.store, 'city', '')}
            </div>
          </div>
        ),
      },
      {
        accessor: 'brand',
        Header: 'Brand',
        textAlignment: 'center',
        minWidth: 60,
        Cell: ({ value }: any) => <p>{value.name}</p>,
      },
      {
        accessor: 'CallFrom',
        Header: 'From',
        textAlignment: 'center',
        minWidth: 60,
        Cell: (value: any) => <p>{value.row.original.CallFrom}</p>,
      },
      {
        accessor: 'CallType',
        Header: 'Call Status',
        textAlignment: 'center',
        minWidth: 50,
        Cell: (value: any) => {
          if (
            value.row.original.CallType === 'call-attempt' ||
            value.row.original.CallType === 'incomplete' ||
            value.row.original.CallType === 'client-hangup'
          ) {
            value.row.original.CallType = 'Initiated'
          }

          if (value.row.original.CallType === 'completed') {
            value.row.original.CallType = 'answered'
          }

          return <p>{value.row.original.CallType}</p>
        },
      },
      {
        accessor: 'date',
        Header: 'Date & Time',
        textAlignment: 'center',
        minWidth: 100,
        Cell: (date: any) => <p>{date.row.original.StartTime}</p>,
      },
      {
        accessor: 'RecordingUrl',
        Header: 'Audio',
        textAlignment: 'left',
        minWidth: 350,
        Cell: ({ value }: any) => {
          const url = value?.replace(
            /(https:\/\/)(recordings\.exotel\.com\/.*)/,
            `$1${process.env.REACT_APP_EXOTAL_KEY}:${process.env.REACT_APP_EXOTAL_PASSWORD}@$2`
          )

          return (
            <div>
              {url !== '' ? (
                <audio controls>
                  <source src={url} type="audio/ogg" />{' '}
                  <source src={url} type="audio/wav" />{' '}
                  <source src={url} type="audio/mpeg" />{' '}
                </audio>
              ) : (
                ''
              )}
            </div>
          )
        },
      },
    ],
    []
  )

  useEffect(() => {
    if (totalRowSize > pageSize) {
      const from = (page - 1) * pageSize
      const to = from + pageSize
      setCountForm(from + 1)
      setCountTo(to)
    } else {
      setCountForm(1)
      setCountTo(totalRowSize)
    }
  }, [page, pageSize, recordData, theme, totalRowSize])
  useEffect(() => {
    // setGraphLoading(true)
    const controller = new AbortController()

    let match
    if (storeVal === null && brandVal === null) {
      if (roleProvided === 'super admin') {
        match = {
          StartTime: { $gte: week[0], $lte: week[1] },
        }
      } else if (roleProvided === 'admin') {
        match = {
          brand: brandID?.[0],
          StartTime: { $gte: week[0], $lte: week[1] },
        }
      } else if (roleProvided === 'CRM manager') {
        match = {
          brand: brandDetails?.[0]?.value,
          StartTime: { $gte: week[0], $lte: week[1] },
        }
      } else if (roleProvided === 'brand manager') {
        match = {
          brand: brandID?.[0],
          StartTime: { $gte: week[0], $lte: week[1] },
        }
      } else if (roleProvided === 'user') {
        match = {
          brand: brandID?.[0],
          store: storeID,
          StartTime: { $gte: week[0], $lte: week[1] },
        }
      } else if (roleProvided === 'admin manager') {
        match = {
          brand: brandID?.[0],
          store: storeID,
          StartTime: { $gte: week[0], $lte: week[1] },
        }
      }
    } else if (roleProvided === 'admin' && brandID) {
      match = {
        StartTime: {
          $gte: week[0],
          $lte: week[1],
        },
        store: storeVal !== null ? [storeVal] : null,
        brand: brandID?.[0],
      }
    } else if (roleProvided === 'CRM manager' && brandID) {
      match = {
        StartTime: {
          $gte: week[0],
          $lte: week[1],
        },
        store: storeVal !== null ? [storeVal] : null,
        brand: brandVal,
      }
    } else if (roleProvided === 'admin manager' && brandID) {
      match = {
        StartTime: {
          $gte: week[0],
          $lte: week[1],
        },
        store: storeVal !== null ? [storeVal] : null,
        brand: brandID?.[0],
      }
    } else if (roleProvided === 'brand manager' && brandID) {
      match = {
        StartTime: {
          $gte: week[0],
          $lte: week[1],
        },
        store: storeVal !== null ? [storeVal] : null,
        brand: brandID?.[0],
      }
    } else {
      match = {
        StartTime: {
          $gte: week[0],
          $lte: week[1],
        },
        store: storeVal !== null ? [storeVal] : null,
        brand: brandVal,
      }
    }
    const clean = (obj: any) => {
      for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName]
        }
      }
      return obj
    }
    const mat = clean(match)

    const match1 = JSON.stringify(mat)

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_OVERALL_CALLSTATUS}?match=${match1}`,
      (res: AxiosResponse) => {
        setGraphLoading(false)
        setLoading(false)
        const data = get(res, 'data.data')
        setWeeklyData(sortBy(data, (o: any) => o._id))
      },
      (err: AxiosError) => {
        setGraphLoading(false)

        console.error(err)
      },
      controller.signal
    )
    let matchMonth
    if (storeVal === null && brandVal === null) {
      if (roleProvided === 'super admin') {
        matchMonth = {
          StartTime: { $gte: monthRange[0], $lte: monthRange[1] },
        }
      } else if (roleProvided === 'admin') {
        matchMonth = {
          brand: brandID?.[0],
          StartTime: { $gte: monthRange[0], $lte: monthRange[1] },
        }
      } else if (roleProvided === 'CRM manager') {
        matchMonth = {
          brand: brandDetails?.[0]?.value,
          StartTime: { $gte: monthRange[0], $lte: monthRange[1] },
        }
      } else if (roleProvided === 'brand manager') {
        matchMonth = {
          brand: brandID?.[0],
          StartTime: { $gte: monthRange[0], $lte: monthRange[1] },
        }
      } else if (roleProvided === 'user') {
        matchMonth = {
          brand: brandID?.[0],
          store: storeID,
          StartTime: { $gte: monthRange[0], $lte: monthRange[1] },
        }
      } else if (roleProvided === 'admin manager') {
        matchMonth = {
          brand: brandID?.[0],
          store: storeID,
          StartTime: { $gte: monthRange[0], $lte: monthRange[1] },
        }
      }
    } else if (roleProvided === 'admin') {
      matchMonth = {
        StartTime: {
          $gte: monthRange[0],
          $lte: monthRange[1],
        },
        store: storeVal !== null ? [storeVal] : null,
        brand: brandID?.[0],
      }
    } else if (roleProvided === 'CRM manager') {
      matchMonth = {
        StartTime: {
          $gte: monthRange[0],
          $lte: monthRange[1],
        },
        store: storeVal !== null ? [storeVal] : null,
        brand: brandVal,
      }
    } else if (roleProvided === 'brand admin') {
      matchMonth = {
        StartTime: {
          $gte: monthRange[0],
          $lte: monthRange[1],
        },
        brand: brandID?.[0],
        store: storeVal !== null ? [storeVal] : null,
      }
    } else if (roleProvided === 'admin manager') {
      matchMonth = {
        StartTime: {
          $gte: monthRange[0],
          $lte: monthRange[1],
        },
        brand: brandID?.[0],
        store: storeVal !== null ? [storeVal] : null,
      }
    } else {
      matchMonth = {
        StartTime: {
          $gte: monthRange[0],
          $lte: monthRange[1],
        },
        brand: brandVal,
        store: storeVal !== null ? [storeVal] : null,
      }
    }

    const mat1 = clean(matchMonth)

    const match2 = JSON.stringify(mat1)

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.MONTH_CALL_WISE}?match=${match2}`,
      (res: AxiosResponse) => {
        setGraphLoading(false)

        setLoading(false)
        const data = get(res, 'data.data')

        setQuaterlyData(sortBy(data, (o: any) => o._id))
      },
      (err: AxiosError) => {
        setGraphLoading(false)

        console.error(err)
      },
      controller.signal
    )
    let matchHalfYear
    if (storeVal === null && brandVal === null) {
      if (roleProvided === 'super admin') {
        matchHalfYear = {
          StartTime: { $gte: halfYearRange[0], $lte: halfYearRange[1] },
        }
      } else if (roleProvided === 'admin') {
        matchHalfYear = {
          brand: brandID?.[0],
          StartTime: { $gte: halfYearRange[0], $lte: halfYearRange[1] },
        }
      } else if (roleProvided === 'CRM manager') {
        matchHalfYear = {
          brand: brandDetails?.[0]?.value,
          StartTime: { $gte: halfYearRange[0], $lte: halfYearRange[1] },
        }
      } else if (roleProvided === 'brand manager') {
        matchHalfYear = {
          brand: brandID?.[0],
          StartTime: { $gte: halfYearRange[0], $lte: halfYearRange[1] },
        }
      } else if (roleProvided === 'user') {
        matchHalfYear = {
          brand: brandID?.[0],
          store: storeID,
          StartTime: { $gte: halfYearRange[0], $lte: halfYearRange[1] },
        }
      } else if (roleProvided === 'admin manager') {
        matchHalfYear = {
          brand: brandID?.[0],
          store: storeID,
          StartTime: { $gte: halfYearRange[0], $lte: halfYearRange[1] },
        }
      }
    } else if (roleProvided === 'admin') {
      matchHalfYear = {
        StartTime: {
          $gte: halfYearRange[0],
          $lte: halfYearRange[1],
        },
        store: storeVal !== null ? [storeVal] : null,
        brand: brandID?.[0],
      }
    } else if (roleProvided === 'admin manager') {
      matchHalfYear = {
        StartTime: {
          $gte: halfYearRange[0],
          $lte: halfYearRange[1],
        },
        store: storeVal !== null ? [storeVal] : null,
        brand: brandID?.[0],
      }
    } else if (roleProvided === 'CRM manager') {
      matchHalfYear = {
        StartTime: {
          $gte: halfYearRange[0],
          $lte: halfYearRange[1],
        },
        store: storeVal !== null ? [storeVal] : null,
        brand: brandVal,
      }
    } else if (roleProvided === 'brand manager') {
      matchHalfYear = {
        StartTime: {
          $gte: halfYearRange[0],
          $lte: halfYearRange[1],
        },
        store: storeVal !== null ? [storeVal] : null,
        brand: brandID?.[0],
      }
    } else {
      matchHalfYear = {
        StartTime: {
          $gte: halfYearRange[0],
          $lte: halfYearRange[1],
        },
        brand: brandVal,
        store: storeVal !== null ? [storeVal] : null,
      }
    }
    const mat2 = clean(matchHalfYear)

    const match3 = JSON.stringify(mat2)

    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.MONTH_CALL_WISE}?match=${match3}`,
      (res: AxiosResponse) => {
        setGraphLoading(false)

        setLoading(false)
        const data = get(res, 'data.data')
        setHalfYearlyData(sortBy(data, (o: any) => o._id))
      },
      (err: AxiosError) => {
        setGraphLoading(false)

        console.error(err)
      },
      controller.signal
    )
    // return cleanup function to abort request
    return () => {
      controller.abort()
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    brandData,
    storeVal,
    brandVal,
    brandID,
    dispatch,
    halfYearRange,
    monthRange,
    roleProvided,
    storeID,
    weeklyRange,
    week,
  ])

  const storeDetails = state.map((item: any) => ({
    ...item,
    value: item._id,
    label: item.storeName,
    description: item.locality,
    cityName: item.city,
  }))

  return (
    <div>
      <Grid>
        <Grid.Col xs={12} sm={7.5} md={7.5} xl={8.5} lg={8.5}>
          <Title order={2} weight={100} style={{ marginTop: ' 10px' }}>
            Call Log Details
          </Title>
        </Grid.Col>
        <Grid.Col xs={6} sm={2} md={2} xl={1.5} lg={1.5}>
          <Popover
            width={350}
            position="bottom"
            withArrow
            shadow="xl"
            styles={() => ({
              dropdown: {
                backgroundColor: '#f0f6f7',
              },
            })}
          >
            <Popover.Target>
              <Button
                sx={{
                  width: '100%',
                  backgroundColor: 'rgb(160, 213, 239)',
                  '&[data-disabled]': { opacity: 0.4 },
                  '&:hover': {
                    backgroundColor: 'rgb(160, 213, 239)',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                  },
                }}
                loading={loading}
              >
                {!loading ? <IconFilter color="black" /> : ''}
                <span style={{ marginLeft: '6px', color: 'black' }}>
                  Filter
                </span>
              </Button>
            </Popover.Target>

            <Popover.Dropdown>
              <Grid.Col xs={12}>
                <DateRangePicker
                  label="Select Date Range"
                  placeholder="Pick dates range"
                  minDate={dayjs('2022-12-15').toDate()}
                  allowSingleDateInRange={true}
                  maxDate={dayjs(new Date()).toDate()}
                  value={rangedate}
                  range={[
                    dayjs(new Date()).startOf('month').toDate(),
                    dayjs(new Date()).endOf('month').toDate(),
                  ]}
                  onChange={(i: any) => {
                    setLoading(true)
                    setPage(1)
                    if (i[0] && i[1]) {
                      setRangeDate(i)
                      setPage(1)
                      setPageNo(1)
                    }
                  }}
                  dayClassName={(date, modifiers) =>
                    cx({
                      [classes.outside]: modifiers.outside,
                    })
                  }
                />
              </Grid.Col>
              {roleProvided === 'super admin' ? (
                <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Select
                    label="Select Brand"
                    placeholder="Select Brand"
                    itemComponent={SelectItem}
                    data={brandDetails}
                    limit={brandDetails.length}
                    disabled={brandDetails.length === 0}
                    searchable
                    maxDropdownHeight={200}
                    value={brandVal !== null ? brandVal : ''}
                    nothingFound="No Data Found"
                    clearable
                    onChange={(value: any) => {
                      setLoading(true)
                      setGraphLoading(true)
                      setPage(1)
                      setPageNo(1)
                      setBrandVal(value)
                      setStoreVal(null)
                      setWeeklyData(null)
                      setQuaterlyData(null)
                      setHalfYearlyData(null)
                      setRecordData([])
                      setTotalRowSize(0)
                    }}
                    styles={() => ({
                      item: {
                        // applies styles to selected item
                        '&[data-selected]': {
                          '&, &:hover': {
                            backgroundColor:
                              theme.colorScheme === 'dark'
                                ? theme.colors.teal[9]
                                : theme.colors.teal[1],
                            color:
                              theme.colorScheme === 'dark'
                                ? theme.white
                                : theme.colors.teal[9],
                          },
                        },

                        // applies styles to hovered item (with mouse or keyboard)
                        '&[data-hovered]': {},
                      },
                    })}
                  />
                </Grid.Col>
              ) : null}
              {brandID !== null && roleProvided === 'CRM manager' ? (
                <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  {' '}
                  <Select
                    label="Select Brand"
                    placeholder="Select Brand"
                    itemComponent={SelectItem}
                    data={brandDetails}
                    limit={brandDetails.length}
                    disabled={brandDetails.length === 0}
                    searchable
                    maxDropdownHeight={200}
                    defaultValue={brandDetails?.[0]?.value}
                    value={
                      brandVal !== null ? brandVal : brandDetails?.[0]?.value
                    }
                    nothingFound="No Data Found"
                    clearable
                    onChange={(value: any) => {
                      setGraphLoading(true)
                      setLoading(true)
                      setPage(1)
                      setPageNo(1)
                      setBrandVal(value)
                      setStoreVal(null)
                      setWeeklyData(null)
                      setQuaterlyData(null)
                      setHalfYearlyData(null)
                      setRecordData([])
                      setTotalRowSize(0)
                    }}
                    styles={() => ({
                      item: {
                        // applies styles to selected item
                        '&[data-selected]': {
                          '&, &:hover': {
                            backgroundColor:
                              theme.colorScheme === 'dark'
                                ? theme.colors.teal[9]
                                : theme.colors.teal[1],
                            color:
                              theme.colorScheme === 'dark'
                                ? theme.white
                                : theme.colors.teal[9],
                          },
                        },

                        // applies styles to hovered item (with mouse or keyboard)
                        '&[data-hovered]': {},
                      },
                    })}
                  />
                </Grid.Col>
              ) : null}

              <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                {roleProvided === 'super admin' ||
                (brandID !== null && roleProvided === 'admin') ||
                (brandID !== null && roleProvided === 'CRM manager') ||
                (brandID !== null && roleProvided === 'brand manager') ||
                (brandID !== null && roleProvided === 'admin manager') ? (
                  <Select
                    label="Select Store"
                    placeholder="Select store"
                    clearable
                    searchable
                    itemComponent={SelectItem}
                    data={storeDetails}
                    limit={storeDetails.length}
                    disabled={storeDetails.length === 0}
                    maxDropdownHeight={300}
                    nothingFound="No Data Found"
                    value={storeVal !== null ? storeVal : ''}
                    onChange={(value: any) => {
                      setLoading(true)
                      setGraphLoading(true)
                      setPage(1)
                      setPageNo(1)
                      setStoreVal(value)
                      setWeeklyData(null)
                      setQuaterlyData(null)
                      setHalfYearlyData(null)
                      setTotalRowSize(0)
                      setRecordData([])
                    }}
                    styles={() => ({
                      item: {
                        // applies styles to selected item
                        '&[data-selected]': {
                          '&, &:hover': {
                            backgroundColor:
                              theme.colorScheme === 'dark'
                                ? theme.colors.teal[9]
                                : theme.colors.teal[1],
                            color:
                              theme.colorScheme === 'dark'
                                ? theme.white
                                : theme.colors.teal[9],
                          },
                        },

                        // applies styles to hovered item (with mouse or keyboard)
                        '&[data-hovered]': {},
                      },
                    })}
                  />
                ) : null}
              </Grid.Col>
            </Popover.Dropdown>
          </Popover>
        </Grid.Col>
        <Grid.Col xs={6} sm={2.5} md={2.5} lg={2} xl={2}>
          {' '}
          <Button
            sx={{
              width: '100%',
              backgroundColor: 'rgb(160, 213, 239)',
              '&[data-disabled]': { opacity: 0.4 },
              '&:hover': {
                backgroundColor: 'rgb(160, 213, 239)',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              },
            }}
            onClick={() => {
              onDownload()
            }}
            disabled={recordData.length === 0}
            loading={exportLoading}
          >
            {!exportLoading ? <IconTableExport color="black" /> : ''}
            <Text color="black">Export Report</Text>
          </Button>
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col xs={12} ref={filterRef}>
          <Grid sx={{ paddingTop: 20 }}>
            <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Paper
                shadow="xs"
                p="md"
                sx={{ width: '100%', height: 'auto', borderRadius: '10px' }}
              >
                <div className={classes.GraphWrapper}>
                  <Box sx={{ width: '100%', height: '240px' }}>
                    {!graphLoading ? (
                      <VerticalBarChart
                        title={` 7 Days Call Status  (${moment(new Date())
                          .subtract(6, 'days')
                          .format('DD MMM YYYY')}-${moment(new Date()).format(
                          ' DD MMM YYYY'
                        )})`}
                        data={{
                          labels: weeklyDays,
                          datasets: [
                            {
                              label: 'Answered Call',
                              data: [
                                weeklyData
                                  ?.map((val: any) =>
                                    weeklyDays[0] === val?._id
                                      ? val?.answered
                                      : 0
                                  )
                                  .find((k: number) => k !== 0),
                                weeklyData
                                  ?.map((val: any) =>
                                    weeklyDays[1] === val?._id
                                      ? val?.answered
                                      : 0
                                  )
                                  .find((k: number) => k !== 0),
                                weeklyData
                                  ?.map((val: any) =>
                                    weeklyDays[2] === val?._id
                                      ? val?.answered
                                      : 0
                                  )
                                  .find((k: number) => k !== 0),
                                weeklyData
                                  ?.map((val: any) =>
                                    weeklyDays[3] === val?._id
                                      ? val?.answered
                                      : 0
                                  )
                                  .find((k: number) => k !== 0),
                                weeklyData
                                  ?.map((val: any) =>
                                    weeklyDays[4] === val?._id
                                      ? val?.answered
                                      : 0
                                  )
                                  .find((k: number) => k !== 0),
                                weeklyData
                                  ?.map((val: any) =>
                                    weeklyDays[5] === val?._id
                                      ? val?.answered
                                      : 0
                                  )
                                  .find((k: number) => k !== 0),
                                weeklyData
                                  ?.map((val: any) =>
                                    weeklyDays[6] === val?._id
                                      ? val?.answered
                                      : 0
                                  )
                                  .find((k: number) => k !== 0),
                              ],

                              barPercentage: 0.7,
                              backgroundColor: '#78F288',
                            },
                            {
                              label: 'Missed Call',
                              data: [
                                weeklyData
                                  ?.map((val: any) =>
                                    weeklyDays[0] === val?._id ? val?.missed : 0
                                  )
                                  .find((k: number) => k !== 0),
                                weeklyData
                                  ?.map((val: any) =>
                                    weeklyDays[1] === val?._id ? val?.missed : 0
                                  )
                                  .find((k: number) => k !== 0),
                                weeklyData
                                  ?.map((val: any) =>
                                    weeklyDays[2] === val?._id ? val?.missed : 0
                                  )
                                  .find((k: number) => k !== 0),
                                weeklyData
                                  ?.map((val: any) =>
                                    weeklyDays[3] === val?._id ? val?.missed : 0
                                  )
                                  .find((k: number) => k !== 0),
                                weeklyData
                                  ?.map((val: any) =>
                                    weeklyDays[4] === val?._id ? val?.missed : 0
                                  )
                                  .find((k: number) => k !== 0),
                                weeklyData
                                  ?.map((val: any) =>
                                    weeklyDays[5] === val?._id ? val?.missed : 0
                                  )
                                  .find((k: number) => k !== 0),
                                weeklyData
                                  ?.map((val: any) =>
                                    weeklyDays[6] === val?._id ? val?.missed : 0
                                  )
                                  .find((k: number) => k !== 0),
                              ],

                              barPercentage: 0.7,
                              backgroundColor: '#F55B5B',
                            },
                          ],
                        }}
                      />
                    ) : (
                      <Box>
                        <Skeleton height={20} radius="xl" />
                        <Skeleton height={20} mt={8} radius="xl" />
                        <Skeleton height={180} mt={8} />
                      </Box>
                    )}
                  </Box>
                </div>
              </Paper>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Paper
                shadow="xs"
                p="md"
                sx={{ width: '100%', height: 'auto', borderRadius: '10px' }}
              >
                <div className={classes.GraphWrapper}>
                  <Box sx={{ width: '100%', height: '240px' }}>
                    {!graphLoading ? (
                      <VerticalBarChart
                        title={` 3 Month Call Status `}
                        data={{
                          labels: monthDate,
                          datasets: [
                            {
                              label: 'Answered Call',
                              data: [
                                quaterlyData
                                  ?.map((val: any) =>
                                    monthData[0] === val?._id
                                      ? val?.answered
                                      : 0
                                  )
                                  .find((k: number) => k !== 0),
                                quaterlyData
                                  ?.map((val: any) =>
                                    monthData[1] === val?._id
                                      ? val?.answered
                                      : 0
                                  )
                                  .find((k: number) => k !== 0),
                                quaterlyData
                                  ?.map((val: any) =>
                                    monthData[2] === val?._id
                                      ? val?.answered
                                      : 0
                                  )
                                  .find((k: number) => k !== 0),
                              ],
                              barPercentage: 0.7,
                              backgroundColor: '#78F288',
                            },
                            {
                              label: 'Missed Call',
                              data: [
                                quaterlyData
                                  ?.map((val: any) =>
                                    monthData[0] === val?._id ? val?.missed : 0
                                  )
                                  .find((k: number) => k !== 0),
                                quaterlyData
                                  ?.map((val: any) =>
                                    monthData[1] === val?._id ? val?.missed : 0
                                  )
                                  .find((k: number) => k !== 0),
                                quaterlyData
                                  ?.map((val: any) =>
                                    monthData[2] === val?._id ? val?.missed : 0
                                  )
                                  .find((k: number) => k !== 0),
                              ],
                              barPercentage: 0.7,
                              backgroundColor: '#F55B5B',
                            },
                          ],
                        }}
                      />
                    ) : (
                      <Box>
                        <Skeleton height={20} radius="xl" />
                        <Skeleton height={20} mt={8} radius="xl" />
                        <Skeleton height={180} mt={8} />
                      </Box>
                    )}
                  </Box>
                </div>
              </Paper>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paper
                shadow="xs"
                p="md"
                sx={{ height: 'auto', borderRadius: '10px', width: '100%' }}
              >
                <Box sx={{ height: '300px', width: '100%' }}>
                  {!graphLoading ? (
                    <VerticalBarChart
                      title={` 6 Month Call Status  `}
                      data={{
                        labels: YearDatas,
                        datasets: [
                          {
                            label: 'Answered Call',
                            data: [
                              halfYearlyData
                                ?.map((val: any) =>
                                  YearData[0] === val?._id ? val?.answered : 0
                                )
                                .find((k: number) => k !== 0),
                              halfYearlyData
                                ?.map((val: any) =>
                                  YearData[1] === val?._id ? val?.answered : 0
                                )
                                .find((k: number) => k !== 0),
                              halfYearlyData
                                ?.map((val: any) =>
                                  YearData[2] === val?._id ? val?.answered : 0
                                )
                                .find((k: number) => k !== 0),
                              halfYearlyData
                                ?.map((val: any) =>
                                  YearData[3] === val?._id ? val?.answered : 0
                                )
                                .find((k: number) => k !== 0),
                              halfYearlyData
                                ?.map((val: any) =>
                                  YearData[4] === val?._id ? val?.answered : 0
                                )
                                .find((k: number) => k !== 0),
                              halfYearlyData
                                ?.map((val: any) =>
                                  YearData[5] === val?._id ? val?.answered : 0
                                )
                                .find((k: number) => k !== 0),
                            ],
                            barPercentage: 0.7,
                            backgroundColor: '#78F288',
                          },
                          {
                            label: 'Missed Call',
                            data: [
                              halfYearlyData
                                ?.map((val: any) =>
                                  YearData[0] === val?._id ? val?.missed : 0
                                )
                                .find((k: number) => k !== 0),
                              halfYearlyData
                                ?.map((val: any) =>
                                  YearData[1] === val?._id ? val?.missed : 0
                                )
                                .find((k: number) => k !== 0),
                              halfYearlyData
                                ?.map((val: any) =>
                                  YearData[2] === val?._id ? val?.missed : 0
                                )
                                .find((k: number) => k !== 0),
                              halfYearlyData
                                ?.map((val: any) =>
                                  YearData[3] === val?._id ? val?.missed : 0
                                )
                                .find((k: number) => k !== 0),
                              halfYearlyData
                                ?.map((val: any) =>
                                  YearData[4] === val?._id ? val?.missed : 0
                                )
                                .find((k: number) => k !== 0),
                              halfYearlyData
                                ?.map((val: any) =>
                                  YearData[5] === val?._id ? val?.missed : 0
                                )
                                .find((k: number) => k !== 0),
                            ],

                            barPercentage: 0.7,
                            backgroundColor: '#F55B5B',
                          },
                        ],
                      }}
                    />
                  ) : (
                    <Box>
                      <Skeleton height={20} radius="xl" />
                      <Skeleton height={20} mt={8} radius="xl" />
                      <Skeleton height={180} mt={8} />
                    </Box>
                  )}
                </Box>
              </Paper>
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col xs={12}>
          <Paper
            shadow="xs"
            p="md"
            sx={{ width: '100%', height: 'auto', borderRadius: '10px' }}
          >
            <div style={{ marginTop: '10px' }}>
              <CustomTable
                pageFrom={countFrom}
                pageTo={countTo}
                total={pageNo}
                gridApi={(instance) => {}}
                totalRecords={!loading ? totalRowSize : 1}
                checkboxSelection={false}
                recordsPerPage={pageSize}
                page={page}
                height={900}
                onPageChange={(p: any) => setPage(p)}
                recordsPerPageOptions={PAGESIZES}
                onRecordsPerPageChange={setPageSize}
                loading={!!loading}
                rowData={!loading ? recordData : []}
                colDef={colDef}
                positionGiven={true}
              />
            </div>
          </Paper>
        </Grid.Col>
      </Grid>
    </div>
  )
}
